.faq {
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 100px;
  margin-top: 67px;
}

.faq__section__separator {
  max-width: 1200px;
  margin-left: 0px;
}

.faq__section__title {
  font-size: 26px;
  font-weight: 300;
  float: left;
  width: 300px;
  padding-right: 20px;
}

.faq__questions {
  overflow: hidden;
  max-width: 800px;
}

.faq__question {
  padding-top: 8px;
}

.faq__question__title {
  font-size: 16px;
  font-weight: 600;
  padding-top: 3px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.faq__question__title:hover {
  background-color: #f5f5f5;
}

.faq__question__arrow {
  width: 15px;
  margin-right: 15px;
}

.faq__question__answer {
  padding-left: 30px;
  padding-top: 10px;
  display: none;
}

.faq__answer__open {
  display: block;
}

@media (max-width: 750px) {
  .faq__section__title {
    font-size: 26px;
    font-weight: 300;
    float: none;
    width: unset;
  }
}
.faq__question__answer ul li {
  color: #ff7300;
}

.faq__question__answer ul li span {
  color: black;
}

.faq__question__answer ul {
  padding-left: 20px;
}